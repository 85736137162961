/* You can add global styles to this file, and also import other style files */

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.important {
  color: #ff0000 !important;
  font-weight: normal;
  margin-left: 0px;
  padding: 0;
}

/*body {
  min-height: 1280vh;
  margin: 0 auto;
}
*/
header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
  width: 100%;
}

body .container:nth-child(2) {
  margin-top: 148px;
}

body .container {
  max-width: 1370px !important;
}

ul.dropdown-menu.show {
  background-color: #f9f9f9;
  border: none;
  padding: 0px;
  border-radius: 0px;
}

  ul.dropdown-menu.show li.nav-item {
    margin: 0px;
  }

  ul.dropdown-menu.show li :hover {
    background-color: #ff9355 !important;
  }

  ul.dropdown-menu.show li:last-child:hover {
    background-color: white !important;
  }
  
  ul.dropdown-menu.show li .submenu {
    background-color: white !important;
  }

  ul.dropdown-menu.show li .submenu li a{
    color:#000 !important;
  }

  ul.dropdown-menu.show li .submenu li:hover a {
    color: #fff !important;
  }

  ul.dropdown-menu.show li {
    margin: 0px;
  }

    ul.dropdown-menu.show li:hover a {
      color: #fff !important;
    }

    ul.dropdown-menu.show li a {
      padding: 5px 15px;
    }

button.btn.dropdown-toggle.show {
  border-color: transparent;
}

header img.img-thumbnail {
  padding: 0;
  border: none;
}

.navbar-expand-sm .navbar-nav .nav-link, .navbar-expand-sm .navbar-nav button.btn.dropdown-toggle {
  font-size: 16px;
  font-weight: bold;
}

.navbar-expand-sm .navbar-nav li.nav-item {
  position: relative;
  margin: 0px 4px;
}

  .navbar-expand-sm .navbar-nav li.nav-item:before {
    content: "";
    position: absolute;
    background: #fe9259;
    width: 100%;
    height: 2px;
    bottom: 0;
    opacity: 0;
  }

ul.dropdown-menu li.nav-item:before {
  display: none;
}

.navbar-expand-sm .navbar-nav li.nav-item:hover:before {
  opacity: 1;
}

span.email_name {
  font-size: 15px;
  font-weight: 600;
  padding: 10px;
  margin-right: 12px;
}

smart-list-box[_ngcontent-ng-cli-universal-c80] {
  height: 550px !important;
}

div#navbarSupportedContent button.btn.btn-primary {
  background: #ff914d;
  border-radius: 5px;
  color: #fff;
  border: 1px solid #ff914d;
  font-style: normal;
  font-weight: bold;
  line-height: 18px;
  padding: 10px 15px;
  text-transform: uppercase;
}

app-client-list p {
  font-size: 20px;
  font-weight: 500;
  margin-top: 40px;
}

div#formWindowContent h5 {
  color: #000;
  margin: 20px 0px;
}

  div#formWindowContent h5 b {
    color: #204f8b;
  }

smart-list-item[selected] .smart-content {
  color: #1a4d88;
  border-color: #4b7ebd;
}

.smart-header-section {
  background-color: #204f8b !important;
}

.pBarWrapper .currentPTab em.pNumber {
  color: #fff;
  border: 2px solid #000000;
}

.pBarWrapper .selected .pBar, .pBarWrapper .selected em.pNumber b {
  background: #000000;
}

.smart-table thead th {
  font-size: 16px;
  background-color: #204f8b !important;
}

h2#tableLabel {
  margin: 50px 0px;
  color: #000;
}

.smart-table th:hover .column-menu:after, .smart-table th[column-menu-button] .column-menu:after {
  color: #fff;
}

.options button.smart-button {
  margin: 0px;
  background: #ff914d;
  color: #fff;
  border: 1px solid #ff914d;
  font-weight: 600;
}

.options .smart-button {
  margin: 10px;
}

.options {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

  .options button.smart-button:hover {
    background: #204f8b;
    color: #fff;
    border-color: #204f8b;
  }

.smart-table > .smart-container {
  overflow: initial;
}

.smart-element.smart-table::-webkit-scrollbar {
  width: 15px;
  background: rgb(26 77 135 / 15%);
  border-radius: 8px;
}

.smart-element.smart-table::-webkit-scrollbar-thumb {
  background: #4b7ebd;
  border: 0px solid rgb(26 77 135 / 15%);
  border-radius: 8px;
}

.smart-table-column-menu.wrapper.smart-table-columns-menu .smart-table-menu-item {
  height: auto;
  margin-bottom: 0px;
  padding-top: 0px;
}

@media screen and (max-width: 767px) {
  .navbar-expand-sm .navbar-nav .nav-link, .navbar-expand-sm .navbar-nav button.btn.dropdown-toggle {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.float-right {
  float: right;
}

.text-align-center {
  text-align: center;
}

.iframe-style {
  width: 100%;
  border: none;
  padding-top: 15px;
}


.Main_banner_npbs {
  padding-top: 200px;
  padding-bottom: 200px;
  position: relative;
  height: calc(100vh - 299px);
  display: flex;
  align-items: center;
  justify-content: center;
}

  .Main_banner_npbs:before {
    content: "";
    position: absolute;
    background: rgb(0 0 0 / 40%);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .Main_banner_npbs h1 {
    color: #fff;
    z-index: 11;
    position: relative;
    text-align: center;
  }

footer.footer_npbs {
  background-color: #2b2b2b;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}

  footer.footer_npbs p {
    color: #fff;
    margin-bottom: 0px;
    font-size: 15px;
  }


  footer.footer_npbs .social_footer {
    margin-bottom: 15px;
  }

    footer.footer_npbs .social_footer a {
      margin: 0px 15px;
    }

.Main_banner_npbs p {
  text-align: center;
  color: #fff;
  z-index: 11;
  position: relative;
  font-size: 20px;
  width: 100%;
  max-width: 600px;
  margin: auto;
}
